
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































.cta-social-projects {
  @extend %text-center;
}

.cta-social-projects__title,
.cta-social-projects__text {
  margin: 0 auto var(--spacing-s);

  @include mq(l) {
    max-width: col(6, 10);
  }
}

.carousel.cta-social-projects__pictures {
  --item-width: 29rem;
  --item-ratio: 0.935;
  --scrollbar-width: 0;

  margin-top: var(--spacing-m);

  ::v-deep .carousel__item {
    position: relative;
    width: var(--item-width);
    max-width: 80vw;
    height: auto;
    aspect-ratio: var(--item-ratio);
  }

  @include mq(l) {
    --item-width: 32rem;
    --item-ratio: 4/3;
  }
}

.cta-social-projects__picture {
  @extend %border-radius-small;
  @include image-fit;
}
